<template>
	<v-card class="mt-2 mb-5 pb-2" rounded="xl">
		<v-card-title>{{ $t('documents.linkYourDocuments') }}</v-card-title>
		<v-card-text>
			<v-row class="mb-2">
				<v-col>
					<v-chip
						small
						:ripple="false"
						:color="docFile.uploaded ? 'success' : 'error'"
						class="mx-1"
						@click="docFile.uploaded ? callDownload(docFile.parentID) : callNoDocument()"
						v-for="docFile in linkedDocuments"
						:key="`docFile${docFile.id}`"
					>
						<v-icon left> mdi-cloud-download </v-icon>
						{{ docFile.name }}
					</v-chip>
				</v-col>
			</v-row>
			<p class="text-body-1">
				{{ $t('documents.linkDocumentsHelp') }}
			</p>
			<h3 class="mb-3">
				{{ $t('documents.linkCVLetterAlert') }}
			</h3>
			<v-form ref="form" v-model="valid">
				<v-select
					v-model="docType"
					:items="availableDocumentTypes"
					:label="$t('documents.docType')"
					:rules="rules.docType"
					required
					item-text="name"
					item-value="key"
					return-object
					outlined
					@input="document = null"
				/>
				<v-select
					v-show="docType"
					v-model="document"
					:items="filteredDocuments"
					:label="$t('documents.document')"
					:rules="rules.document"
					required
					item-text="name"
					item-value="id"
					return-object
					outlined
				/>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn color="primary" text @click="callUpload()">
				{{ $t('settings.upload') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EventDocuments',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			valid: false,
			document: null,
			docType: null,
			rules: {
				document: [(v) => !!v || this.$t('rules.required')],
				docType: [(v) => !!v || this.$t('rules.required'), (v) => (!!v && this.availableDocumentTypes.includes(v)) || this.$t('rules.notSupported')],
			},
		}
	},
	watch: {
		authenticated() {
			if (this.authenticated) this.fetchDocuments()
		},
	},
	computed: {
		linkedDocuments() {
			return this.attendance ? this.attendance.documents.filter((e) => e.uploaded) : []
		},
		filteredDocuments() {
			return this.documents && this.docType ? this.documents.filter((e) => e.type.key == this.docType.key) : []
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			attendance: 'attendances/attendance',
			documents: 'documents/documents',
			availableDocumentTypes: 'data/availableDocumentTypes',
		}),
	},
	created() {
		this.fetchAvailableDocumentTypes()
		if (this.authenticated) this.fetchDocuments()
	},
	methods: {
		callUpload() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.uploadDocumentAttendance({
				documentID: this.document.id,
				attendanceID: this.attendance ? this.attendance.id : 0,
			}).then(({ success }) => {
				if (success) {
					this.clearUpload()
				}
			})
		},
		callDownload(parentID) {
			this.loading = true
			this.downloadDocumentAttendance({ parentID }).then(() => {
				this.loading = false
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		...mapActions('documents', ['fetchDocuments', 'uploadDocumentAttendance', 'downloadDocumentAttendance', 'noDocument']),
		...mapActions('data', ['fetchAvailableDocumentTypes']),
	},
}
</script>
